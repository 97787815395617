import React from "react";
import { Link } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const OverdraftProtection = () => {
  const title = "Personal Checking Accounts - Overdraft Protection",
    description =
      "WaFd Bank offers its customers multiple overdraft protection options to ensure that you are covered the way that you want to be. Learn more here.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/checking-account/overdraft-protection"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/checking-account",
      title: "Checking"
    },
    {
      id: 3,
      active: true,
      title: "Overdraft Services"
    }
  ];

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Overdraft Services</h1>
        <h3>
          WaFd Bank is here to help you avoid fees wherever possible. We have two services you can take advantage of to
          avoid overdraft fees, as WaFd does not offer traditional overdraft protection services.
        </h3>
        <h3>
          If your debit card is used for a purchase and there aren't enough funds in your account, the transaction will
          be declined and no charge will be assessed.
        </h3>
        <h3>
          But, without overdraft protection, if you have a check, automatic withdrawal (ACH withdrawal) set to come out
          of your account and there isn't enough in your account to cover it, the check or ACH will be returned and a
          nonsufficient funds fee of $30 will be charged to your account. To avoid that fee, we offer two convenient
          overdraft protection options:
        </h3>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="thead-gray">
              <tr>
                <th style={{ width: "23%" }}></th>
                <th className="font-weight-normal align-baseline">
                  <strong>Overdraft Protection</strong>
                  <br />
                  Funds will transfer from another account of your choice in case of an overdraft.
                </th>
                <th className="font-weight-normal align-baseline">
                  <strong>Overdraft Line of Credit</strong>
                  <br />
                  Funds will transfer from your line of credit, which does not require an existing balance. Borrow and
                  pay back as needed.
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Setup Fees:</strong>
                </td>
                <td id="mt-setup-fees">$0, only one account holder is needed to set it up</td>
                <td id="ol-setup-fees">$21 Credit Report Fee</td>
              </tr>
              <tr>
                <td>
                  <strong>Overdraft Transfer Fee:</strong>
                </td>
                <td id="mt-overdraft-transfer-fee">No transfer fee</td>
                <td id="ol-overdraft-transfer-fee">No transfer fee</td>
              </tr>
              <tr>
                <td>
                  <strong>Coverage:</strong>
                </td>
                <td id="mt-coverage">
                  Checks, automatic payments, and recurring payments, as long as funds are available in your linked
                  account to cover the transaction.
                </td>
                <td id="ol-coverage">
                  Checks, ATM withdrawals, debit card purchases, automatic payments, and recurring payments, up to your
                  credit limit.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Payment:</strong>
                </td>
                <td id="mt-coverage">N/A</td>
                <td id="ol-coverage">
                  Pay back the balance over time with an automatic payment of 2.5% of the outstanding balance or $50,
                  whichever is greater.
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Link to="/locations" id="mt-locations-cta" className="btn btn-primary w-100 w-lg-auto">
                    Find a Branch
                  </Link>
                </td>
                <td>
                  <Link to="/locations" id="ol-locations-cta" className="btn btn-primary w-100 w-lg-auto">
                    Find a Branch
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="text-sm text-gray-60">
          <p>
            <strong>Overdraft Line of Credit</strong>
          </p>
          <ul>
            <li id="ol-item-1">
              You may request an unsecured line with limits from $500 to $5,000, depending on your credit qualifications
              and approval.
            </li>
            <li id="ol-item-2">
              A low application fee of just $21 is due at time of application to cover the cost of your credit report.
            </li>
            <li id="ol-item-3">
              No annual fee. Subject to change. Please see our{" "}
              <Link to="/personal-banking/account-fees" id="account-fees-page-link">
                schedule of consumer fees and service charges
              </Link>
              .
            </li>
            <li id="ol-item-4">
              The line will be repaid through automatic payment from the linked checking account. The payment amount is
              based on 2.5% of outstanding balance or $50, whichever is greater.
            </li>
            <li id="ol-item-5">Enjoy unlimited line of credit advances (up to your available balance).</li>
            <li id="ol-item-6">Rates are subject to change.</li>
            <li id="ol-item-7">
              Overdraft Line of Credit is subject to additional terms and conditions and credit approval, with a maximum
              term of 10 years.
            </li>
          </ul>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default OverdraftProtection;
